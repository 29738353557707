<template>
    <div>
        <!-- 控价管理》增加/修改 -->
        <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen destroy-on-close :show-close="false" :modal-append-to-body="false">
            <!-- 头 -->
            <el-row class="dialog_top">
                <span>{{cur_title}}</span>
                <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
            </el-row>

            <!-- 体 -->
            <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

                <el-row style="overflow: auto; padding-right:10px;" :style="{height: (content_heigth+'px')}">
                    <el-form  :model="form_data" label-width="155px" style="margin-bottom: 12px;">

                        <el-form-item label="客户">
                            <el-select v-model="form_data.cpid" filterable remote reserve-keyword :remote-method="getUserDataList" placeholder="请选择客户" size="mini" style="width:100%">
                                <el-option
                                    v-for="item in userDataList"
                                    :key="item.cpid"
                                    :label="item.cpid+'('+item.company_name+')'"
                                    :value="item.cpid">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="是否开启控价功能">
                            <el-radio-group v-model="form_data.state" size="mini" >
                                <el-radio label="0">开启</el-radio>
                                <el-radio label="1">关闭</el-radio>
                            </el-radio-group>
                        </el-form-item>

                        <el-form-item label="红名单是否生效">
                            <el-radio-group v-model="form_data.red_phone_b" size="mini" >
                                <el-radio label="0">生效</el-radio>
                                <el-radio label="1">不生效</el-radio>
                            </el-radio-group>
                        </el-form-item>

                        <el-form-item label="可控价运营商">
                            <el-checkbox-group v-model="form_data.opers_array" size="mini">
                                <el-checkbox label="0">移动</el-checkbox>
                                <el-checkbox label="1">联通</el-checkbox>
                                <el-checkbox label="2">电信</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>

                        <el-form-item label="可控价省份">
                            <el-checkbox-group v-model="form_data.provinces_array" size="mini">
                                <el-checkbox v-for="item in provList" :label="item.id+''" @change="provinceChange($event,item.id)">{{item.province}}</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                        
                        <el-collapse v-model="activeNames" style="padding-left: 40px">
                            <el-collapse-item title="屏蔽地市" name="1">
                                <el-form-item label="">
                                    <el-row v-for="(item1, index1) in cityList" :key="index1">
                                        <el-row v-for="(items, index2, index1) in selected_prov_id_array" :key="index2">
                                            <el-row v-if="((item1.prov_id == items.prov_id) || items.prov_id == 0) && item1.city_array.length > 1">
                                                {{item1.prov_name}}:
                                                <el-checkbox-group v-model="items.citys_array" size="mini">
                                                    <el-checkbox v-for="(item2, index3, index2, index1) in item1.city_array" :label="item2.city+''" :key="item2.city" @change="cityChange($event,item1.prov_id,item2.city)">{{item2.city}}</el-checkbox>
                                                </el-checkbox-group>
                                            </el-row>
                                        </el-row>
                                    </el-row>
                                </el-form-item>
                            </el-collapse-item>
                        </el-collapse>

                        <el-form-item label="起始发送数">
                            <el-input type="text" v-model="form_data.start_num" @focus="start_num_focus" 
                                oninput="value=value.replace(/[^\d.]/g,'')"
                                placeholder="当日达到多少成功量后才能触发（默认：3000）" size="mini">
                            </el-input>
                        </el-form-item>

                        <el-form-item label="控价频次(1-100整数)">
                            <el-input type="text" maxlength="3" v-model="form_data.phone_day_num"  @focus="phone_day_num_focus" 
                                oninput="value=value.replace(/[^\d.]/g,'')"
                                placeholder="单手机号每天允许被控价的最大次数（默认：1）" size="mini"></el-input>
                        </el-form-item>

                        <el-form-item label="不分流关键字">
                            <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 30}" v-model="form_data.keywords" 
                                    placeholder="多个用英文逗号分割" size="mini"></el-input>
                        </el-form-item>

                        <el-form-item label="24小时控价比">
                            <!-- @focus="hh24_focus" @input="input_change_hh24($event)" value="" -->
                            <el-input type="text" maxlength="5" v-model="form_data.hh24" @input="inputEnter_hh24"
                                style="width:50%;vertical-align: middle;"
                                placeholder="（范围：1-100，支持2位小数点）" size="mini">
                                <template slot="append">%</template>
                            </el-input>
                            <el-button type="primary" @click="input_change_hh24(form_data.hh24)" size="mini" style="margin-left:1%;">作用全部时间</el-button>
                        </el-form-item>
                        <el-form-item label="0点-1点控价比">
                            <el-input type="text" maxlength="5" v-model="form_data.hh_00" @input="inputEnter_hh00"
                                placeholder="（范围：1-100，支持2位小数点）" size="mini"
                                style="vertical-align: middle;">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="1点-2点控价比">
                            <el-input type="text" maxlength="5" v-model="form_data.hh_01" @input="inputEnter_hh01"
                                placeholder="（范围：1-100，支持2位小数点）" size="mini"
                                style="vertical-align: middle;">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="2点-3点控价比">
                            <el-input type="text" maxlength="5" v-model="form_data.hh_02" @input="inputEnter_hh02"
                                placeholder="（范围：1-100，支持2位小数点）" size="mini"
                                style="vertical-align: middle;">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="3点-4点控价比">
                            <el-input type="text" maxlength="5" v-model="form_data.hh_03" @input="inputEnter_hh03"
                                placeholder="（范围：1-100，支持2位小数点）" size="mini"
                                style="vertical-align: middle;">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="4点-5点控价比">
                            <el-input type="text" maxlength="5" v-model="form_data.hh_04" @input="inputEnter_hh04"
                                placeholder="（范围：1-100，支持2位小数点）" size="mini"
                                style="vertical-align: middle;">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="5点-6点控价比">
                            <el-input type="text" maxlength="5" v-model="form_data.hh_05" @input="inputEnter_hh05"
                                placeholder="（范围：1-100，支持2位小数点）" size="mini"
                                style="vertical-align: middle;">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="6点-7点控价比">
                            <el-input type="text" maxlength="5" v-model="form_data.hh_06" @input="inputEnter_hh06"
                                placeholder="（范围：1-100，支持2位小数点）" size="mini"
                                style="vertical-align: middle;">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="7点-8点控价比">
                            <el-input type="text" maxlength="5" v-model="form_data.hh_07" @input="inputEnter_hh07"
                                placeholder="（范围：1-100，支持2位小数点）" size="mini"
                                style="vertical-align: middle;">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="8点-9点控价比">
                            <el-input type="text" maxlength="5" v-model="form_data.hh_08" @input="inputEnter_hh08"
                                placeholder="（范围：1-100，支持2位小数点）" size="mini"
                                style="vertical-align: middle;">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="9点-10点控价比">
                            <el-input type="text" maxlength="5" v-model="form_data.hh_09" @input="inputEnter_hh09"
                                placeholder="（范围：1-100，支持2位小数点）" size="mini"
                                style="vertical-align: middle;">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="10点-11点控价比">
                            <el-input type="text" maxlength="5" v-model="form_data.hh_10" @input="inputEnter_hh10"
                                placeholder="（范围：1-100，支持2位小数点）" size="mini"
                                style="vertical-align: middle;">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="11点-12点控价比">
                            <el-input type="text" maxlength="5" v-model="form_data.hh_11" @input="inputEnter_hh11"
                                placeholder="（范围：1-100，支持2位小数点）" size="mini"
                                style="vertical-align: middle;">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="12点-13点控价比">
                            <el-input type="text" maxlength="5" v-model="form_data.hh_12" @input="inputEnter_hh12"
                                placeholder="（范围：1-100，支持2位小数点）" size="mini"
                                style="vertical-align: middle;">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="13点-14点控价比">
                            <el-input type="text" maxlength="5" v-model="form_data.hh_13" @input="inputEnter_hh13"
                                placeholder="（范围：1-100，支持2位小数点）" size="mini"
                                style="vertical-align: middle;">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="14点-15点控价比">
                            <el-input type="text" maxlength="5" v-model="form_data.hh_14" @input="inputEnter_hh14"
                                placeholder="（范围：1-100，支持2位小数点）" size="mini"
                                style="vertical-align: middle;">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="15点-16点控价比">
                            <el-input type="text" maxlength="5" v-model="form_data.hh_15" @input="inputEnter_hh15"
                                placeholder="（范围：1-100，支持2位小数点）" size="mini"
                                style="vertical-align: middle;">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="16点-17点控价比">
                            <el-input type="text" maxlength="5" v-model="form_data.hh_16" @input="inputEnter_hh16"
                                placeholder="（范围：1-100，支持2位小数点）" size="mini"
                                style="vertical-align: middle;">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="17点-18点控价比">
                            <el-input type="text" maxlength="5" v-model="form_data.hh_17" @input="inputEnter_hh17"
                                placeholder="（范围：1-100，支持2位小数点）" size="mini"
                                style="vertical-align: middle;">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="18点-19点控价比">
                            <el-input type="text" maxlength="5" v-model="form_data.hh_18" @input="inputEnter_hh18"
                                placeholder="（范围：1-100，支持2位小数点）" size="mini"
                                style="vertical-align: middle;">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="19点-20点控价比">
                            <el-input type="text" maxlength="5" v-model="form_data.hh_19" @input="inputEnter_hh19"
                                placeholder="（范围：1-100，支持2位小数点）" size="mini"
                                style="vertical-align: middle;">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="20点-21点控价比">
                            <el-input type="text" maxlength="5" v-model="form_data.hh_20" @input="inputEnter_hh20"
                                placeholder="（范围：1-100，支持2位小数点）" size="mini"
                                style="vertical-align: middle;">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="21点-22点控价比">
                            <el-input type="text" maxlength="5" v-model="form_data.hh_21" @input="inputEnter_hh21"
                                placeholder="（范围：1-100，支持2位小数点）" size="mini"
                                style="vertical-align: middle;">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="22点-23点控价比">
                            <el-input type="text" maxlength="5" v-model="form_data.hh_22" @input="inputEnter_hh22"
                                placeholder="（范围：1-100，支持2位小数点）" size="mini"
                                style="vertical-align: middle;">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="23点-24点控价比">
                            <el-input type="text" maxlength="5" v-model="form_data.hh_23" @input="inputEnter_hh23"
                                placeholder="（范围：1-100，支持2位小数点）" size="mini"
                                style="vertical-align: middle;">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" @click="onSubmit()" size="mini">保存</el-button>
                        </el-form-item>
                    </el-form>
                </el-row>
            </el-row>
        </el-dialog>
    </div>
</template>
<script>
import API from '../../api/api';

export default {
    // components: {
    // },
    props:{
        prop_change_i:0,
        prop_type:'',//类型 add:增加  upd:修改
        prop_item:{},//客户控价对象
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 
                if(this.prop_type === "upd"){//修改
                    this.cur_title = "客户控价的修改";
                    setTimeout(() => {
                        //获取数据
                        this.getData();

                        this.getProvList();

                        this.getCityList();
                    }, 1);
                }else{
                    this.cur_title = "客户控价的增加";
                    setTimeout(() => {
                        this.getProvList();

                        this.getCityList();

                        this.selected_prov_id_array = [{"prov_id":0,"citys_array":[]}];//增加默认选全国
                    }, 1);
                }
            }
        }
    },
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度
            

            userDataList:[],//客户列表数据-请求接口获取

            provList:[],//省份列表数据-请求接口获取

            activeNames:[],//屏蔽地市折叠属性

            selected_prov_id_array: [],//被选中的省份id和被选中的屏蔽地市数组 [{"prov_id":0,"citys_array":[]}]
            cityList:[],//地市列表数据

            cur_title:"",//页面标题

            form_data:{
                cpid:"",//客户账号
                state:"0",//状态 0:开启 1:关闭
                red_phone_b:"0",//红名单是否生效 0:生效 1:不生效
                opers:"0,1,2",//可控价运营商,0:移动;1联通,2电信（多个用半角逗号隔开）
                opers_array:["0","1","2"],//可控价运营商数组
                provinces:"0",//可控价省份，放的是省份id，多个用半角逗号隔开 0:全国
                provinces_array:["0"],//可控价省份数组
                no_control_city:[],//屏蔽省份下的地市
                start_num:"",//起始发送数，即当日达到多少成功量后才能触发
                phone_day_num:"",//单手机号每天允许被控价的最大次数（默认：1）
                keywords:"",//不分流关键字
                hh24:"",//24小时控价比
                hh_00:"",//0点-1点控价比
                hh_01:"",//1点-2点控价比
                hh_02:"",//2点-3点控价比
                hh_03:"",//3点-4点控价比
                hh_04:"",//4点-5点控价比
                hh_05:"",//5点-6点控价比
                hh_06:"",//6点-7点控价比
                hh_07:"",//7点-8点控价比
                hh_08:"",//8点-9点控价比
                hh_09:"",//9点-10点控价比
                hh_10:"",//10点-11点控价比
                hh_11:"",//11点-12点控价比
                hh_12:"",//12点-13点控价比
                hh_13:"",//13点-14点控价比
                hh_14:"",//14点-15点控价比
                hh_15:"",//15点-16点控价比
                hh_16:"",//16点-17点控价比
                hh_17:"",//17点-18点控价比
                hh_18:"",//18点-19点控价比
                hh_19:"",//19点-20点控价比
                hh_20:"",//20点-21点控价比
                hh_21:"",//21点-22点控价比
                hh_22:"",//22点-23点控价比
                hh_23:"",//23点-24点控价比
            },
           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();

        //获取客户列表数据-请求接口获取
        this.getUserDataList("");

    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
        },

        //数据清除
        dataClear(){
            // console.log("数据清除");
            this.form_data = this.$options.data().form_data;//单个恢复初始化
        },
        getData(){
            this.form_data.cpid = this.prop_item.cpid;//客户账号
            this.form_data.state = this.prop_item.state.toString();//状态 0:开启 1:关闭
            this.form_data.red_phone_b = this.prop_item.red_phone_b.toString();//红名单是否生效 0:生效 1:不生效
            this.form_data.opers = this.prop_item.opers;//可控的运营商，多个使用半角逗号隔开 0：移动；1：联通；2：电信
            this.form_data.provinces = this.prop_item.provinces+"";//可控的省份，放的是省份id，多个用半角逗号隔开，0：表示全国
            this.form_data.no_control_city = this.prop_item.no_control_city_array;//屏蔽省份下的地市
            this.form_data.start_num = this.prop_item.start_num;//起始发送数，即当日达到多少成功量后才能触发
            this.form_data.phone_day_num = this.prop_item.phone_day_num;//控价频次(1-100整数),即单手机号每天允许被控价的最大次数
            this.form_data.keywords = this.prop_item.keywords;//不分流关键字
            this.form_data.hh_00 = this.prop_item.control_rate.hh_00;//0点-1点控价比
            this.form_data.hh_01 = this.prop_item.control_rate.hh_01;//1点-2点控价比
            this.form_data.hh_02 = this.prop_item.control_rate.hh_02;//2点-3点控价比
            this.form_data.hh_03 = this.prop_item.control_rate.hh_03;//3点-4点控价比
            this.form_data.hh_04 = this.prop_item.control_rate.hh_04;//4点-5点控价比
            this.form_data.hh_05 = this.prop_item.control_rate.hh_05;//5点-6点控价比
            this.form_data.hh_06 = this.prop_item.control_rate.hh_06;//6点-7点控价比
            this.form_data.hh_07 = this.prop_item.control_rate.hh_07;//7点-8点控价比
            this.form_data.hh_08 = this.prop_item.control_rate.hh_08;//8点-9点控价比
            this.form_data.hh_09 = this.prop_item.control_rate.hh_09;//9点-10点控价比
            this.form_data.hh_10 = this.prop_item.control_rate.hh_10;//10点-11点控价比
            this.form_data.hh_11 = this.prop_item.control_rate.hh_11;//11点-12点控价比
            this.form_data.hh_12 = this.prop_item.control_rate.hh_12;//12点-13点控价比
            this.form_data.hh_13 = this.prop_item.control_rate.hh_13;//13点-14点控价比
            this.form_data.hh_14 = this.prop_item.control_rate.hh_14;//14点-15点控价比
            this.form_data.hh_15 = this.prop_item.control_rate.hh_15;//15点-16点控价比
            this.form_data.hh_16 = this.prop_item.control_rate.hh_16;//16点-17点控价比
            this.form_data.hh_17 = this.prop_item.control_rate.hh_17;//17点-18点控价比
            this.form_data.hh_18 = this.prop_item.control_rate.hh_18;//18点-19点控价比
            this.form_data.hh_19 = this.prop_item.control_rate.hh_19;//19点-20点控价比
            this.form_data.hh_20 = this.prop_item.control_rate.hh_20;//20点-21点控价比
            this.form_data.hh_21 = this.prop_item.control_rate.hh_21;//21点-22点控价比
            this.form_data.hh_22 = this.prop_item.control_rate.hh_22;//22点-23点控价比
            this.form_data.hh_23 = this.prop_item.control_rate.hh_23;//23点-24点控价比

            this.form_data.opers_array = this.form_data.opers.split(",");//可发运营商数组
            this.form_data.provinces_array = this.prop_item.provinces.split(",");//可控价省份数组
            
            // 屏蔽省份下的地市数组
            if(this.prop_item.no_control_city_array.length == 0){
                this.selected_prov_id_array = [{"prov_id":0,"citys_array":[]}];
            }else{
                this.activeNames=['1'];
                for(var x = 0; x < this.prop_item.no_control_city_array.length; x++){
                    let item = this.prop_item.no_control_city_array[x];

                    var obj = {prov_id: item.prov_id};
                    let city_array_one = "";
                    for(var j = 0;j < item.city_array.length; j++){
                        let item2 = item.city_array[j];
                        if(j == 0){
                            city_array_one += item2.city;
                        }else{
                            city_array_one += ","+item2.city;
                        }
                    }
                    obj.citys_array = city_array_one.split(",");
                    this.selected_prov_id_array.push(obj);
                }
            }
            
        },

         //获取客户列表数据-请求接口获取
         getUserDataList(query){
            //请求接口
            API.UserServlet({
                param: "listAISelect",
                company:"",//公司名称
                son_cpid_show:"0",//是否展示子账号 0：不展示 1:展示
                searchID:query,//输入框填写的查询条件
                loading_onoff:false
            }).then((res) => {
                if (res.recode === 0) {
                    this.userDataList = res.list;//客户列表数据--请求接口获取
                }
            });
        },

        //获取省份列表数据
        getProvList(){
            //请求接口
            API.ProvinceServlet({
                param: "getProv"
            }).then((res) => {
                if (res.recode === 0) {
                    this.provList = res.provinceList;//省份列表数据-请求接口获取
                    var obj = {id: 0, province: "全国" };
                    this.provList.unshift(obj);
                }
            });
        },

        //省份改变调用方法
        provinceChange(event, id){
            // console.log("省份改变调用方法:id="+id);
            if (event) {// 如果是选中
                // 把选中的id存入数组
                if(id == 0){//选中了全国
                    this.form_data.provinces_array = ["0"];
                    this.selected_prov_id_array = [{"prov_id":0,"citys_array":[]}];
                    this.activeNames = [];
                }else{
                    var index = this.form_data.provinces_array.indexOf("0");
                    if(index >-1 ){
                        this.form_data.provinces_array.splice(index, 1); //删除全国
                    }
                    this.selected_prov_id_array = this.selected_prov_id_array.filter(t => t.prov_id != "0");

                    var obj = {prov_id: id};
                    obj.citys_array = [];
                    this.selected_prov_id_array.push(obj);
                    this.activeNames = ['1'];
                }
            } else {//未选中
                var selected_prov_id_array_new = [];
                for(var i = 0;i<this.selected_prov_id_array.length;i++){
                    var one = this.selected_prov_id_array[i];
                    if(one.prov_id != id+""){
                        var obj = {prov_id: one.prov_id};
                        obj.citys_array = one.citys_array;
                        selected_prov_id_array_new.push(obj);
                    }
                }
                this.selected_prov_id_array = selected_prov_id_array_new;
            }
            
        },
        //获取地市列表数据
        getCityList(){
            //请求接口
            API.UserPriceControlServlet({
                param: "cityList"
            }).then((res) => {
                if (res.recode === 0) {
                    this.cityList = res.list;//省份列表数据-请求接口获取
                }
            });
        },
        //地市改变调用方法
        cityChange(event, prov_id_2, city_2){
            // console.log("省份改变调用方法:id="+id);
            if (event) {// 如果是选中
                if(this.selected_prov_id_array.length == 0){
                    var obj_1 = {prov_id: prov_id_2};
                    let array_2 = [];
                    array_2.push(city_2);
                    obj_1.citys_array = array_2;
                    this.selected_prov_id_array.push(obj_1);
                }else{
                    for(var i = 0; i < this.selected_prov_id_array.length; i++){
                        let item = this.selected_prov_id_array[i];
                        if(item.prov_id == prov_id_2){
                            let result = item.citys_array.find(item => item === city_2)
                            // 如果result不为undefined，则说明包含'name'为'bar'的对象 
                            if (result) {//包含
                                // console.log('包含')
                            } else {//不包含
                                // console.log('不包含')
                                item.citys_array.push(city_2);
                            }
                        }else{
                            let result2 = this.selected_prov_id_array.find(item => item.prov_id == prov_id_2)
                            // 如果result不为undefined，则说明包含'name'为'bar'的对象 
                            if (result2) {//包含
                                // console.log('包含')
                            } else {//不包含
                                // console.log('不包含')
                                var obj_1 = {prov_id: prov_id_2};
                                let array_2 = [];
                                array_2.push(city_2);
                                obj_1.citys_array = array_2;
                                this.selected_prov_id_array.push(obj_1);
                            }
                        }
                    }
                }
            } else {//未选中
                let array_new = [];
                for(var i = 0; i < this.selected_prov_id_array.length; i++){
                    let item1 = this.selected_prov_id_array[i];
                    if(item1.prov_id == prov_id_2){
                        var obj_1 = {prov_id: item1.prov_id};
                        let array_2 = [];
                        for(var j = 0; j < item1.citys_array.length; j++){
                            let item2 = item1.citys_array[j];
                            if(item2 != city_2){
                                array_2.push(city_2);
                                obj_1.citys_array = array_2;
                            }
                        }
                        if(array_2.length > 0){
                            array_new.push(obj_1);
                        }else{
                            this.form_data.provinces_array = this.filteredItems(this.form_data.provinces_array, prov_id_2);
                        }
                    }else{
                        array_new.push(item1);
                    }
                }
                this.selected_prov_id_array = array_new;
            }
        },
        //去除数组中的某一个元素
        filteredItems(item_obj, item_one) {
            return item_obj.filter(item => item != item_one);
            
        },
        //start_num：在 Input 获得焦点时触发
        start_num_focus(){
            if(this.form_data.start_num == "" || this.form_data.start_num == null){
                this.$set(this.form_data,'start_num','3000');
            }
        },
        //phone_day_num Input 获得焦点时触发
        phone_day_num_focus(){
            if(this.form_data.phone_day_num == "" || this.form_data.phone_day_num == null){
                this.$set(this.form_data,'phone_day_num','1');
            }
        },
        //hh24_focus Input 获得焦点时触发
        hh24_focus(){
            if(this.form_data.hh24 == "" || this.form_data.hh24 == null){
                this.$set(this.form_data,'hh24','0');
                this.$set(this.form_data,'hh_00','0');
                this.$set(this.form_data,'hh_01','0');
                this.$set(this.form_data,'hh_02','0');
                this.$set(this.form_data,'hh_03','0');
                this.$set(this.form_data,'hh_04','0');
                this.$set(this.form_data,'hh_05','0');
                this.$set(this.form_data,'hh_06','0');
                this.$set(this.form_data,'hh_07','0');
                this.$set(this.form_data,'hh_08','0');
                this.$set(this.form_data,'hh_09','0');
                this.$set(this.form_data,'hh_10','0');
                this.$set(this.form_data,'hh_11','0');
                this.$set(this.form_data,'hh_12','0');
                this.$set(this.form_data,'hh_13','0');
                this.$set(this.form_data,'hh_14','0');
                this.$set(this.form_data,'hh_15','0');
                this.$set(this.form_data,'hh_16','0');
                this.$set(this.form_data,'hh_17','0');
                this.$set(this.form_data,'hh_18','0');
                this.$set(this.form_data,'hh_19','0');
                this.$set(this.form_data,'hh_20','0');
                this.$set(this.form_data,'hh_21','0');
                this.$set(this.form_data,'hh_22','0');
                this.$set(this.form_data,'hh_23','0');
            }
        },
        //作用于全部时间
        input_change_hh24(value){
            this.$set(this.form_data,'hh_00',value);
            this.$set(this.form_data,'hh_01',value);
            this.$set(this.form_data,'hh_02',value);
            this.$set(this.form_data,'hh_03',value);
            this.$set(this.form_data,'hh_04',value);
            this.$set(this.form_data,'hh_05',value);
            this.$set(this.form_data,'hh_06',value);
            this.$set(this.form_data,'hh_07',value);
            this.$set(this.form_data,'hh_08',value);
            this.$set(this.form_data,'hh_09',value);
            this.$set(this.form_data,'hh_10',value);
            this.$set(this.form_data,'hh_11',value);
            this.$set(this.form_data,'hh_12',value);
            this.$set(this.form_data,'hh_13',value);
            this.$set(this.form_data,'hh_14',value);
            this.$set(this.form_data,'hh_15',value);
            this.$set(this.form_data,'hh_16',value);
            this.$set(this.form_data,'hh_17',value);
            this.$set(this.form_data,'hh_18',value);
            this.$set(this.form_data,'hh_19',value);
            this.$set(this.form_data,'hh_20',value);
            this.$set(this.form_data,'hh_21',value);
            this.$set(this.form_data,'hh_22',value);
            this.$set(this.form_data,'hh_23',value);
        },
        inputEnter_hh24(){
            this.form_data.hh24 = this.form_data.hh24.replace(/[^\d.]/g, "");// 清除"数字"和"."以外的字符
            this.form_data.hh24 = this.form_data.hh24.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入一位小数点
        },
        inputEnter_hh00(){
            this.form_data.hh_00 = this.form_data.hh_00.replace(/[^\d.]/g, "");// 清除"数字"和"."以外的字符
            this.form_data.hh_00 = this.form_data.hh_00.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入一位小数点
        },
        inputEnter_hh01(){
            this.form_data.hh_01 = this.form_data.hh_01.replace(/[^\d.]/g, "");// 清除"数字"和"."以外的字符
            this.form_data.hh_01 = this.form_data.hh_01.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入一位小数点
        },
        inputEnter_hh02(){
            this.form_data.hh_02 = this.form_data.hh_02.replace(/[^\d.]/g, "");// 清除"数字"和"."以外的字符
            this.form_data.hh_02 = this.form_data.hh_02.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入一位小数点
        },
        inputEnter_hh03(){
            this.form_data.hh_03 = this.form_data.hh_03.replace(/[^\d.]/g, "");// 清除"数字"和"."以外的字符
            this.form_data.hh_03 = this.form_data.hh_03.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入一位小数点
        },
        inputEnter_hh04(){
            this.form_data.hh_04 = this.form_data.hh_04.replace(/[^\d.]/g, "");// 清除"数字"和"."以外的字符
            this.form_data.hh_04 = this.form_data.hh_04.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入一位小数点
        },
        inputEnter_hh05(){
            this.form_data.hh_05 = this.form_data.hh_05.replace(/[^\d.]/g, "");// 清除"数字"和"."以外的字符
            this.form_data.hh_05 = this.form_data.hh_05.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入一位小数点
        },
        inputEnter_hh06(){
            this.form_data.hh_06 = this.form_data.hh_06.replace(/[^\d.]/g, "");// 清除"数字"和"."以外的字符
            this.form_data.hh_06 = this.form_data.hh_06.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入一位小数点
        },
        inputEnter_hh07(){
            this.form_data.hh_07 = this.form_data.hh_07.replace(/[^\d.]/g, "");// 清除"数字"和"."以外的字符
            this.form_data.hh_07 = this.form_data.hh_07.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入一位小数点
        },
        inputEnter_hh08(){
            this.form_data.hh_08 = this.form_data.hh_08.replace(/[^\d.]/g, "");// 清除"数字"和"."以外的字符
            this.form_data.hh_08 = this.form_data.hh_08.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入一位小数点
        },
        inputEnter_hh09(){
            this.form_data.hh_09 = this.form_data.hh_09.replace(/[^\d.]/g, "");// 清除"数字"和"."以外的字符
            this.form_data.hh_09 = this.form_data.hh_09.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入一位小数点
        },
        inputEnter_hh10(){
            this.form_data.hh_10 = this.form_data.hh_10.replace(/[^\d.]/g, "");// 清除"数字"和"."以外的字符
            this.form_data.hh_10 = this.form_data.hh_10.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入一位小数点
        },
        inputEnter_hh11(){
            this.form_data.hh_11 = this.form_data.hh_11.replace(/[^\d.]/g, "");// 清除"数字"和"."以外的字符
            this.form_data.hh_11 = this.form_data.hh_11.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入一位小数点
        },
        inputEnter_hh12(){
            this.form_data.hh_12 = this.form_data.hh_12.replace(/[^\d.]/g, "");// 清除"数字"和"."以外的字符
            this.form_data.hh_12 = this.form_data.hh_12.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入一位小数点
        },
        inputEnter_hh13(){
            this.form_data.hh_13 = this.form_data.hh_13.replace(/[^\d.]/g, "");// 清除"数字"和"."以外的字符
            this.form_data.hh_13 = this.form_data.hh_13.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入一位小数点
        },
        inputEnter_hh14(){
            this.form_data.hh_14 = this.form_data.hh_14.replace(/[^\d.]/g, "");// 清除"数字"和"."以外的字符
            this.form_data.hh_14 = this.form_data.hh_14.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入一位小数点
        },
        inputEnter_hh15(){
            this.form_data.hh_15 = this.form_data.hh_15.replace(/[^\d.]/g, "");// 清除"数字"和"."以外的字符
            this.form_data.hh_15 = this.form_data.hh_15.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入一位小数点
        },
        inputEnter_hh16(){
            this.form_data.hh_16 = this.form_data.hh_16.replace(/[^\d.]/g, "");// 清除"数字"和"."以外的字符
            this.form_data.hh_16 = this.form_data.hh_16.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入一位小数点
        },
        inputEnter_hh17(){
            this.form_data.hh_17 = this.form_data.hh_17.replace(/[^\d.]/g, "");// 清除"数字"和"."以外的字符
            this.form_data.hh_17 = this.form_data.hh_17.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入一位小数点
        },
        inputEnter_hh18(){
            this.form_data.hh_18 = this.form_data.hh_18.replace(/[^\d.]/g, "");// 清除"数字"和"."以外的字符
            this.form_data.hh_18 = this.form_data.hh_18.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入一位小数点
        },
        inputEnter_hh19(){
            this.form_data.hh_19 = this.form_data.hh_19.replace(/[^\d.]/g, "");// 清除"数字"和"."以外的字符
            this.form_data.hh_19 = this.form_data.hh_19.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入一位小数点
        },
        inputEnter_hh20(){
            this.form_data.hh_20 = this.form_data.hh_20.replace(/[^\d.]/g, "");// 清除"数字"和"."以外的字符
            this.form_data.hh_20 = this.form_data.hh_20.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入一位小数点
        },
        inputEnter_hh21(){
            this.form_data.hh_21 = this.form_data.hh_21.replace(/[^\d.]/g, "");// 清除"数字"和"."以外的字符
            this.form_data.hh_21 = this.form_data.hh_21.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入一位小数点
        },
        inputEnter_hh22(){
            this.form_data.hh_22 = this.form_data.hh_22.replace(/[^\d.]/g, "");// 清除"数字"和"."以外的字符
            this.form_data.hh_22 = this.form_data.hh_22.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入一位小数点
        },
        inputEnter_hh23(){
            this.form_data.hh_23 = this.form_data.hh_23.replace(/[^\d.]/g, "");// 清除"数字"和"."以外的字符
            this.form_data.hh_23 = this.form_data.hh_23.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入一位小数点
        },

        //提交
        onSubmit(){
            if(API.isEmtry(this.form_data.cpid)){
                this.$message.error("请填写客户");
                return;
            }
            if(API.isEmtry(this.form_data.state)){
                this.$message.error("请设置客户控价状态");
                return;
            }
            if(API.isEmtry(this.form_data.red_phone_b)){
                this.$message.error("请设置客户控价红名单是否生效");
                return;
            }
            if(this.form_data.opers_array.length == 0){
                this.$message.error("请选择可控价运营商");
                return;
            }else{
                this.form_data.opers = this.form_data.opers_array.toString();
            }
            if(this.form_data.provinces_array.length == 0){
                this.$message.error("请选择可控价省份");
                return;
            }else{
                this.form_data.provinces = this.form_data.provinces_array.toString();
            }
            if(this.form_data.provinces == "0"){
                this.form_data.no_control_city = [];
            }else{
                let no_control_city_new = [];
                for(var x = 0; x < this.selected_prov_id_array.length; x++){
                    let item = this.selected_prov_id_array[x];

                    var obj_1 = {prov_id: item.prov_id};
                    let array_2 = [];
                    for(var j = 0;j < item.citys_array.length; j++){
                        let item2 = item.citys_array[j];
                        if(item2 != ""){
                            var obj_2 = {city: item2};
                            array_2.push(obj_2);
                            obj_1.city_array = array_2;
                        }
                    }
                    if(array_2.length > 0){
                        no_control_city_new.push(obj_1);
                    }
                }
                this.form_data.no_control_city = JSON.stringify(no_control_city_new);
            }
            if(API.isEmtry(this.form_data.start_num)){
                this.$message.error("请设置客户控价起始发送数");
                return;
            }
            if(API.isEmtry(this.form_data.phone_day_num)){
                this.$message.error("请设置客户控价控价频次");
                return;
            }
            if(API.isEmtry(this.form_data.hh_00)){
                this.$message.error("请设置客户0点-1点控价比");
                return;
            }
            if(API.isEmtry(this.form_data.hh_01)){
                this.$message.error("请设置客户1点-2点控价比");
                return;
            }
            if(API.isEmtry(this.form_data.hh_02)){
                this.$message.error("请设置客户2点-3点控价比");
                return;
            }
            if(API.isEmtry(this.form_data.hh_03)){
                this.$message.error("请设置客户3点-4点控价比");
                return;
            }
            if(API.isEmtry(this.form_data.hh_04)){
                this.$message.error("请设置客户4点-5点控价比");
                return;
            }
            if(API.isEmtry(this.form_data.hh_05)){
                this.$message.error("请设置客户5点-6点控价比");
                return;
            }
            if(API.isEmtry(this.form_data.hh_06)){
                this.$message.error("请设置客户6点-7点控价比");
                return;
            }
            if(API.isEmtry(this.form_data.hh_07)){
                this.$message.error("请设置客户7点-8控价比");
                return;
            }
            if(API.isEmtry(this.form_data.hh_08)){
                this.$message.error("请设置客户8点-9点控价比");
                return;
            }
            if(API.isEmtry(this.form_data.hh_09)){
                this.$message.error("请设置客户9点-10点控价比");
                return;
            }
            if(API.isEmtry(this.form_data.hh_10)){
                this.$message.error("请设置客户10点-11点控价比");
                return;
            }
            if(API.isEmtry(this.form_data.hh_11)){
                this.$message.error("请设置客户11点-12点控价比");
                return;
            }
            if(API.isEmtry(this.form_data.hh_12)){
                this.$message.error("请设置客户12点-13点控价比");
                return;
            }
            if(API.isEmtry(this.form_data.hh_13)){
                this.$message.error("请设置客户13点-14点控价比");
                return;
            }
            if(API.isEmtry(this.form_data.hh_14)){
                this.$message.error("请设置客户14点-15点控价比");
                return;
            }
            if(API.isEmtry(this.form_data.hh_15)){
                this.$message.error("请设置客户15点-16点控价比");
                return;
            }
            if(API.isEmtry(this.form_data.hh_16)){
                this.$message.error("请设置客户16点-17点控价比");
                return;
            }
            if(API.isEmtry(this.form_data.hh_17)){
                this.$message.error("请设置客户17点-18点控价比");
                return;
            }
            if(API.isEmtry(this.form_data.hh_18)){
                this.$message.error("请设置客户18点-19点控价比");
                return;
            }
            if(API.isEmtry(this.form_data.hh_19)){
                this.$message.error("请设置客户19点-20点控价比");
                return;
            }
            if(API.isEmtry(this.form_data.hh_20)){
                this.$message.error("请设置客户20点-21点控价比");
                return;
            }
            if(API.isEmtry(this.form_data.hh_21)){
                this.$message.error("请设置客户21点-22点控价比");
                return;
            }
            if(API.isEmtry(this.form_data.hh_22)){
                this.$message.error("请设置客户22点-23点控价比");
                return;
            }
            if(API.isEmtry(this.form_data.hh_23)){
                this.$message.error("请设置客户23点-24点控价比");
                return;
            }

            var submit_obj = {};
            if(this.prop_type === "upd"){//修改
                submit_obj.param = "updUserControl";
            }else{//增加
                submit_obj.param = "addUserControl";
            }
            submit_obj.cpid=this.form_data.cpid;
            submit_obj.state=this.form_data.state;
            submit_obj.red_phone_b=this.form_data.red_phone_b;
            submit_obj.opers=this.form_data.opers;
            submit_obj.provinces=this.form_data.provinces;
            submit_obj.no_control_city=this.form_data.no_control_city;
            submit_obj.start_num=this.form_data.start_num;
            submit_obj.phone_day_num=this.form_data.phone_day_num;
            submit_obj.keywords=this.form_data.keywords;
            submit_obj.hh_00=this.form_data.hh_00;
            submit_obj.hh_01=this.form_data.hh_01;
            submit_obj.hh_02=this.form_data.hh_02;
            submit_obj.hh_03=this.form_data.hh_03;
            submit_obj.hh_04=this.form_data.hh_04;
            submit_obj.hh_05=this.form_data.hh_05;
            submit_obj.hh_06=this.form_data.hh_06;
            submit_obj.hh_07=this.form_data.hh_07;
            submit_obj.hh_08=this.form_data.hh_08;
            submit_obj.hh_09=this.form_data.hh_09;
            submit_obj.hh_10=this.form_data.hh_10;
            submit_obj.hh_11=this.form_data.hh_11;
            submit_obj.hh_12=this.form_data.hh_12;
            submit_obj.hh_13=this.form_data.hh_13;
            submit_obj.hh_14=this.form_data.hh_14;
            submit_obj.hh_15=this.form_data.hh_15;
            submit_obj.hh_16=this.form_data.hh_16;
            submit_obj.hh_17=this.form_data.hh_17;
            submit_obj.hh_18=this.form_data.hh_18;
            submit_obj.hh_19=this.form_data.hh_19;
            submit_obj.hh_20=this.form_data.hh_20;
            submit_obj.hh_21=this.form_data.hh_21;
            submit_obj.hh_22=this.form_data.hh_22;
            submit_obj.hh_23=this.form_data.hh_23;
            
            API.UserPriceControlServlet(submit_obj).then((res) => {
                if (res.recode === 0) {
                    this.$message.success("恭喜您，提交成功");
                    //关闭
                    this.back();
                    //回调
                    this.$emit("result",'');

                    this.selected_prov_id_array = [];
                }
            });
        },

        //关闭
        back(){
            this.selected_prov_id_array = [];
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },

    }
};

</script>

<style scoped>
/* input输入框后置元素样式 */
::v-deep  .el-input-group__append, .el-input-group__prepend{
    padding: 0 2px;
}
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}

/*form表单 间隔*/
.el-form-item {
  margin-bottom: 10px;
}

.cur_dialog >>>.el-select-dropdown__wrap {
    /* max-height: 123px !important; */
    
}
</style>